.header {
  grid-area: header;
  border-top-left-radius: 2vw;
  background-color: #f9fcff;
  padding: 15rem 20rem;
}

.header > h1 {
  font-size: 30rem;
  font-weight: 500;
}

.header > h1 > span {
  font-weight: 700;
  color: #6135ff;
}
