.details {
  grid-area: details;
  background-color: #f9fcff;
  padding: 0 20rem;
}

.details > .inner-container {
  height: 95%;
  display: grid;
  grid-template-areas:
    "search map"
    "list map"
    "list graph"
    "list graph";
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 2fr 1fr 1fr;
  background-color: #ffffff;
  border-radius: 20rem;
  margin: 10rem 0;
  padding: 10rem;
  box-shadow: 2rem 2rem 10rem 0rem #2e2d2d18;
  overflow: hidden;
}

.search {
  background-color: #f0f3f7;
  border-radius: 20rem;
  padding: 10rem;
  margin: 0 30rem 10rem 10rem;
}

.search > input {
  border: none;
  background-image: none;
  background-color: transparent;
  color: #817c9b;
}

.search > input:focus {
  outline-width: 0;
}

.list-container {
  grid-area: list;
  position: relative;
  margin: 0 10rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-container::-webkit-scrollbar {
  width: 15rem;
}

.list-container::-webkit-scrollbar-track {
  background-color: #eff3f7;
  border: 2rem #ffffff solid;
  border-radius: 7.5rem;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: #dfdaf0;
  width: 15rem;
  border-radius: 7.5rem;
}

.list {
  position: absolute;
  top: 0;
  left: 0;
}

.list-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10rem;
  padding: 10rem;
  margin: 0 5rem;
  border-radius: 5rem;
}

.list-item:hover {
  cursor: pointer;
}

.list-item:hover .list-name {
  color: #9577ff;
}

.list-case-num {
  font-size: 15rem;
  text-align: left;
  font-weight: 500;
}
.list-name {
  font-size: 15rem;
  color: #817c9b;
  text-align: left;
}

.map {
  grid-area: map;
  margin: 10rem;
  background-color: #edeffd;
}

.map:hover {
  cursor: grab;
}

.map:active {
  cursor: grabbing;
}

.graph {
  grid-area: graph;
  background-color: #ffffff;
  position: relative;
}

.graph .graph-title {
  position: absolute;
  top: 20rem;
  left: 0;
  font-size: 15rem;
  font-weight: 700;
}
