.summary {
  display: grid;
  grid-area: summary;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  justify-content: space-around;
  background-color: #f9fcff;
  padding: 10rem 20rem;
  column-gap: 20rem;
}

.summary-card {
  background-color: #ffffff;
  border-radius: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2rem 2rem 10rem 0rem #2e2d2d18;
}

.summary-card > h3 {
  font-size: 15rem;
  font-weight: 700;
  margin: 10rem 0;
}

.summary-card > p {
  font-size: 30rem;
  font-weight: 600;
  margin: 5rem 0;
}

.summary-card:nth-child(1) > p {
  color: #f9345e;
}
.summary-card:nth-child(2) > p {
  color: #fa6400;
}
.summary-card:nth-child(3) > p {
  color: #6bd301;
}
.summary-card:nth-child(4) > p {
  color: #9577ff;
}

.increase-num {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.increase-num > small {
  font-size: 14rem;
  margin: 10rem 5rem;
  color: #817c9b;
  font-weight: 600;
}

.triangle-icon {
  width: 0;
  height: 0;
  border-left: 4rem solid transparent;
  border-right: 4rem solid transparent;
  border-bottom: 4rem solid #6bd301;
}
