.menu {
  grid-area: menu;
  background-color: #1a1053;
}

.menu-item {
  padding: 20rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30rem;
  border-bottom-left-radius: 40rem;
  margin-left: 15rem;
}

.menu-item > h2 {
  font-size: 13rem;
  color: #f9fcff;
  font-weight: 600;
  margin: 10rem 30rem;
  letter-spacing: 2rem;
}

.icon {
  font-size: 20rem;
  color: #f9fcff;
}
